import { Component } from 'react'
import { GTR } from '@farewill/ui/tokens'
import { P, Wrapper, Button } from '@farewill/ui'
import { isAxiosError } from 'lib/effects/api'

const RetryMessage = () => (
  <>
    <P>
      Please try again. There was an error loading the PDF. If this error
      persists please contact engineering.
    </P>
    <Button.Primary type="button" onClick={() => window.location.reload()}>
      Retry
    </Button.Primary>
  </>
)

const MissingPDF = () => (
  <>
    <P>
      Unable to find PDF. If you believe this is an error, please contact
      engineering.
    </P>
  </>
)

type WillPdfState = {
  isLoading: boolean
  errorCode?: number
}

type WillPdfProps = {
  url?: string
  willKey: string
  fetchWillPdfUrl: (willKey: string) => Promise<void>
}

class WillPdf extends Component<WillPdfProps, WillPdfState> {
  state = {
    isLoading: true,
    errorCode: undefined,
  }

  fetchWillPdfUrl = async () => {
    this.setState({ isLoading: true })
    try {
      await this.props.fetchWillPdfUrl(this.props.willKey)
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        this.setState({ errorCode: error?.response?.status || 500 })
      }
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount() {
    this.fetchWillPdfUrl()
  }

  componentDidUpdate(prevProps: WillPdfProps) {
    if (!prevProps.url && this.props.url) {
      window.location.assign(this.props.url)
    }
  }

  render() {
    if (this.state.isLoading) {
      return <P>Loading...</P>
    } else if (this.state.errorCode) {
      return (
        <Wrapper centered padding={[GTR.XL, 0, 0]}>
          {this.state.errorCode === 404 ? <MissingPDF /> : <RetryMessage />}
        </Wrapper>
      )
    }

    return null
  }
}

export default WillPdf
